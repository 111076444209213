import React from "react";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Flex, Stack } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "../../texts/Hero";

interface VLGuideProps {}

const VLGuide: React.FC<VLGuideProps> = () => {
  return (
    <TextAndVisual
      paddingTop="4rem"
      isVisualMedium
      isVisualLeft
      textPart={
        <Stack spacing={6}>
          <Hero>Guide users trough complex products</Hero>
          <BodyText textAlign={"left"}>
            Planning a digital workflow is an art; it involves guiding the user
            through the experience smoothly, avoiding any frustration caused by
            unclear instructions or hidden features. With a well-designed user
            interface, both the learning curve and user errors can be minimised.
          </BodyText>
          <BodyText textAlign={"left"}>
            At Indevit we can assist you by creating user flows that result in
            satisfied users. When we combine our expertise in business systems
            together with design, magic happens! We understand the value of a
            clear user interface and a guiding user experience. We know that
            system complexity raises the demands for user-friendliness.
          </BodyText>
        </Stack>
      }
      visualPart={
        <Flex justifyContent="flex-end">
          <StaticImage
            alt="Guide users trough complex products"
            src="../../../images/indevit-03.jpg"
            placeholder="none"
          />
        </Flex>
      }
    />
  );
};

export default VLGuide;
