import { Section } from "../../layout/Section";
import React from "react";
import { Stack } from "@chakra-ui/react";
import VisualLabCategory from "./VisualLabCategory";
import VLMessages from "./VLMessages";
import VLIdeas from "./VLIdeas";
import VLGuide from "./VLGuide";
import VLSystem from "./VLSystem";

interface VisualLabSectionProps {}

const VisualLabSection: React.FC<VisualLabSectionProps> = () => {
  return (
    <Section>
      <Stack spacing={20}>
        <VisualLabCategory />
        <VLMessages />
        <VLIdeas />
        <VLGuide />
        <VLSystem />
      </Stack>
    </Section>
  );
};

export default VisualLabSection;
