import React from "react";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Flex, Stack } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "../../texts/Hero";
import { Link } from "gatsby";

interface VLMessagesProps { }

const VLMessages: React.FC<VLMessagesProps> = () => {
  return (
    <TextAndVisual
      isVisualMedium
      isVisualLeft
      paddingTop="4rem"
      textPart={
        <Stack spacing={6}>
          <Hero>We make your message pop!</Hero>
          <BodyText textAlign={"left"}>
            Do you have interesting stories or complex processes that you want
            to share with your employees or customers? Perhaps you have company
            policies, onboarding materials, or industry processes that are
            difficult to convey through just videos or text. Let us help you!
          </BodyText>
          <BodyText textAlign={"left"}>
            Together with {" "}
            <Link
              style={{ whiteSpace: "nowrap", textDecoration: "underline", fontWeight: "bold" }}
              to="https://atcontent.fi"
            >
              A.T. Content
            </Link>{" "}
            we have specialised in delivering digital messages in an engaging
            and appealing way. By combining visuals, text, videos, animations,
            and interactive elements, we create an immersive experience that
            effectively communicates your message, capturing and holding the
            recipient's interest.
          </BodyText>
          <BodyText>
            Does it sound interesting? Don't hesitate to
            reach out to learn more about how we can assist you!
          </BodyText>
        </Stack>
      }
      visualPart={
        <Flex justifyContent="flex-end">
          <StaticImage
            alt="We create well-oiled IT solutions"
            src="../../../images/indevit-01b.jpg"
            placeholder="none"
          />
        </Flex>
      }
    />
  );
};

export default VLMessages;
