import { Flex, Grid } from "@chakra-ui/react";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import VerticalTextAndVisual from "../../layout/VerticalTextAndVisual";

interface VisualLabCategoryProps {}

const VisualLabCategory: React.FC<VisualLabCategoryProps> = () => {
  return (
    <Grid
      gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr 1fr 1fr" }}
      gap={8}
    >
      <VerticalTextAndVisual
        subtitle="Content Creation"
        body="Crafting engaging and impactful storytelling and content that captivates audiences and drives meaningful connections."
        icon={
          <StaticImage
            style={{ maxWidth: "11rem" }}
            alt="Content Creation"
            src="../../../images/service-icons-05.png"
            placeholder="none"
          />
        }
      />
      <VerticalTextAndVisual
        subtitle="Prototyping"
        body="Transforming abstract ideas into tangible realities, fueling innovation and validating concepts through iterative design and user testing."
        icon={
          <StaticImage
            style={{ maxWidth: "11rem" }}
            alt="Prototyping"
            src="../../../images/service-icons-06.png"
            placeholder="none"
          />
        }
      />
      <VerticalTextAndVisual
        subtitle="UI/UX design"
        body="Creating user-friendly and visually appealing experiences that make a lasting impact for your audience."
        icon={
          <StaticImage
            alt="UI/UX design"
            style={{ maxWidth: "11rem" }}
            src="../../../images/service-icons-07.png"
            placeholder="none"
          />
        }
      />
      <VerticalTextAndVisual
        subtitle="Design system"
        body="Improve your brand's look and feel across all digital products with our Design System knowledge, making things consistent and easy to use."
        icon={
          <StaticImage
            style={{ maxWidth: "11rem" }}
            alt="Design system"
            src="../../../images/service-icons-08.png"
            placeholder="none"
          />
        }
      />
    </Grid>
  );
};

export default VisualLabCategory;
