import { Box, BoxProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export interface TextAndVisualProps extends BoxProps {
  textPart: ReactNode;
  visualPart: ReactNode;
  isVisualSmall?: boolean;
  isVisualMedium?: boolean;
  isVisualLeft?: boolean;
}

export function TextAndVisual(props: TextAndVisualProps) {
  const {
    textPart,
    visualPart,
    isVisualLeft,
    isVisualSmall,
    isVisualMedium,
    ...chakra } = props;

  let textFlex: string;
  let visualFlex: string;
  if (isVisualSmall) {
    textFlex = "1 1 65%";
    visualFlex = "1 1 35%";
  }
  else if (isVisualMedium) {
    textFlex = "1 1 55%";
    visualFlex = "1 1 45%";
  }
  else {
    textFlex = "1 1 45%";
    visualFlex = "1 1 55%";
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDir={{
        base: "column-reverse",
        lg: isVisualLeft
          ? "row-reverse"
          : "row"
      }}
      {...chakra}>
      <Box
        flex={textFlex}
        pt={{
          base: "40px",
          lg: 0
        }}
        textAlign={{
          base: "center",
          lg: "start"
        }}>
        {textPart}
      </Box>
      <Box
        flex={visualFlex}
        margin="auto"
        pr={{
          lg: isVisualLeft
            ? "75px"
            : 0
        }}
        pl={{
          lg: isVisualLeft
            ? 0
            : "75px"
        }}
      >
        {visualPart}
      </Box>
    </Box>
  )
}