import React from "react";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Flex, Stack } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "../../inputs/Button";
import { Link } from "gatsby";
import { primary } from "../../../constants/colors";
import { Hero } from "../../texts/Hero";

interface VisualLabLinkProps { }

const VLSystem: React.FC<VisualLabLinkProps> = () => {
  return (
    <TextAndVisual
      paddingTop="4rem"
      textPart={
        <Stack spacing={6}>
          <Hero fontSize="2.5rem">
            Business systems, efficient and innovative solutions
          </Hero>
          <BodyText>
            Our company also offers comprehensive services in software
            development, business intelligence, integrations, and architectural
            planning, empowering businesses with efficient and innovative
            solutions.
          </BodyText>
          <Link to="/services/system">
            <Button colorScheme="primary">
              Read more
            </Button>
          </Link>
        </Stack>
      }
      visualPart={
        <Flex
          justifyContent="flex-end"
          padding={{ base: "1rem 0", lg: "2rem 1rem" }}
          backgroundColor={primary}>
          <StaticImage
            alt="Business systems, efficient and innovative solutions"
            src="../../../images/collage-businessystems.png"
            placeholder="none"
          />
        </Flex>
      }
    />
  );
};

export default VLSystem;
