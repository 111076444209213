import { Box, Stack } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React, { ReactNode } from "react";
import { Subtitle } from "../texts/Subtitle";
import { BodyText } from "../texts/BodyText";

interface VerticalTextAndVisualProps {
  icon?: ReactNode;
  subtitle: string;
  body: string;
}

const VerticalTextAndVisual: React.FC<VerticalTextAndVisualProps> = ({
  icon,
  subtitle,
  body,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="0 1rem 0 1rem"
    >
      <Box marginX={{ base: "10%", md: "unset" }} marginBottom="1rem">{icon}</Box>
      <Stack textAlign="center" spacing={{ base: "", lg: 3 }}>
        <Subtitle fontSize="1.25rem" whiteSpace="nowrap">{subtitle}</Subtitle>
        <BodyText>{body}</BodyText>
      </Stack>
    </Box>
  );
};

export default VerticalTextAndVisual;
