import React from "react";
import { Layout } from "../../components/layout/Layout";
import VisualLabSection from "../../components/sections/visuallab/VisualLabSection";
import { VisualLabHero } from "../../components/sections/visuallab/VisualLabHero";
import { MetaTags } from "../../components/MetaTags";

interface VisualLabProps { }

const VisualLab: React.FC<VisualLabProps> = () => {
  return (
    <Layout colorScheme="secondary" heroSection={<VisualLabHero />} isNextSectionInitiallyVisible>
      <VisualLabSection />
    </Layout>
  );
};

export default VisualLab;

export const Head = () => (
  <MetaTags
    title="Visual Lab"
    description="Visual experiences that engage and guide. 
      As bridge builders between business and users, we help customers
      create a seamless digital experiences through intuitive design and guiding content."
    image="visual-lab.jpg"
  />
)