import React from "react";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Flex, Stack } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "../../texts/Hero";

interface VLIdeasProps {}

const VLIdeas: React.FC<VLIdeasProps> = () => {
  return (
    <TextAndVisual
      isVisualMedium
      paddingTop="4rem"
      textPart={
        <Stack spacing={6}>
          <Hero>Visualise your ideas without any code</Hero>
          <BodyText textAlign={"left"}>
            Want to see your digital idea come to life before the development
            process even starts? Then a prototype is the perfect solution for
            you! A prototype is a preliminary visual representation of a digital
            product that showcases its design and main features but doesn't
            contain functioning code. It gives the appearance of the final
            product but is just a simulation. 
          </BodyText>
          <BodyText textAlign={"left"}>
            At Indevit, our team of experts in
            software products can help you create a visual prototype of your
            idea from the very beginning. If you're interested in learning more
            about our prototyping services, feel free to reach out!
          </BodyText>
        </Stack>
      }
      visualPart={
        <Flex justifyContent="flex-end">
          <StaticImage
            alt="We create well-oiled IT solutions"
            src="../../../images/indevit-02.jpg"
            placeholder="none"
          />
        </Flex>
      }
    />
  );
};

export default VLIdeas;
