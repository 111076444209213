import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { BodyText } from "../../texts/BodyText";
import { Hero } from "../../texts/Hero";
import { ImageCollageService } from "../../visuals/ImageCollageService";
import { StaticImage } from "gatsby-plugin-image";

export function VisualLabHero() {
  return (
    <Flex
      as="section"
      display="flex"
      w="100vw"
      flex="1 1 auto"
      mt={{
        base: "3rem",
        lg: 0
      }}
      px={{base: "1rem", md: "3rem" }}
      pl={
        {
          "lg": 9,
          "xl": "10vw",
          "2xl": "10vw",
        }}
      flexDirection={{
        base: "column-reverse",
        lg: "row",
      }}
      alignItems={{
        base: "center",
        lg: "center",
      }}
      justifyContent={{
        base: "flex-end",
        lg: "center",
      }}
    >
      <Box flex={{base: "0 1 26rem", "2xl": "0 1 32rem"}}>
        <Hero
          mt={{
            base: "2rem",
            lg: 0
          }}
          mb="6"
          textAlign={{
            base: "center",
            lg: "left",
          }}
        >
          Visual experiences that engage and guide
        </Hero>
        <BodyText
          textAlign={{
            base: "center",
            lg: "left",
          }}
        >
          As bridge builders between business and users, we help customers
          create seamless digital experiences through intuitive design and
          guiding content.
        </BodyText>
      </Box>
      <ImageCollageService

        boxProps={{
          maxWidth: {
            base: "32rem",
            lg: "46rem",
            "3xl": "64rem"
          }
        }}
        image={
          <StaticImage
            src="../../../images/collage-visuallab.png"
            alt="Realistic hand holding a cog set against the moon"
            placeholder="blurred"
          />
        }
      />
    </Flex>
  );
}
