import React, { ReactNode } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

export interface ImageCollageServiceProps extends BoxProps {
  boxProps?: BoxProps;
  image?: ReactNode;
}

export const ImageCollageService: React.FC<ImageCollageServiceProps> = ({
  boxProps,
  image,
}) => {
  return (
    <Box {...boxProps}>
      <Box position="relative">
        {image}
      </Box>
    </Box>
  );
};
